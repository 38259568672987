import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from "./Pages/Landing";
import Layout from "./Pages/Layout";
import EventsPage from "./Pages/EventsPage";
import FighterPage from "./Pages/FighterPage";
import RankingsPage from "./Pages/RankingsPage";
import NoPage from "./Pages/NoPage";

import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchPage from "./Pages/SearchPage";

import { getEventIds, getEventDetails} from "./API";

import './App.css';

const CACHE_KEY = 'ufcEventsCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours


const theme = createTheme({
  typography: {
    fontFamily: 'Bebas Neue, Arial, sans-serif', // Use Bebas Neue as the default font
    fontSize:'18',
    h1: {
      fontFamily: 'Bebas Neue, Arial, sans-serif', // You can apply it specifically to headers if needed
    },
    h2: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
    },
    h7: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
    },
    body1: {
      fontFamily: 'Bebas Neue, Arial, sans-serif', // Apply it to body text as well
    },
  },
  palette: {
    primary: {
      main: '#1C1C1C', // Black
      light: '#474747', // Lighter shade of black
      dark: '#000000', // Darker shade of black
      contrastText: '#FFFFFF', // White text on primary color
    },
    secondary: {
      main: '#D32F2F', // Red
      light: '#FF6659', // Lighter shade of red
      dark: '#9A0007', // Darker shade of red
      contrastText: '#FFFFFF', // White text on secondary color
    },
    background: {
      default: '#333333', // Dark gray
      paper: '#424242', // Slightly lighter dark gray for paper elements
    },
    text: {
      primary: '#FFFFFF', // White text
      secondary: '#BDBDBD', // Light gray text for secondary text
      disabled: '#757575', // Disabled text color
    },
    success: {
      main: '#4CAF50', // Green
      light: '#80E27E', // Lighter shade of green
      dark: '#087F23', // Darker shade of green
      contrastText: '#FFFFFF', // White text on success color
    },
    warning: {
      main: '#FF9800', // Orange
      light: '#FFC947', // Lighter shade of orange
      dark: '#C66900', // Darker shade of orange
      contrastText: '#FFFFFF', // White text on warning color
    },
    info: {
      main: '#2196F3', // Blue
      light: '#6EC6FF', // Lighter shade of blue
      dark: '#0069C0', // Darker shade of blue
      contrastText: '#FFFFFF', // White text on info color
    },
    error: {
      main: '#F44336', // Bright red
      light: '#FF7961', // Lighter shade of red
      dark: '#BA000D', // Darker shade of red
      contrastText: '#FFFFFF', // White text on error color
    },
    action: {
      active: '#FFFFFF', // White for active icons
      hover: '#474747', // Slightly lighter than primary light for hover state
      selected: '#616161', // Gray for selected elements
      disabled: '#757575', // Gray for disabled elements
      disabledBackground: '#424242', // Background for disabled elements
    },
    divider: '#BDBDBD', // Light gray for dividers
  },
});




function App() {

  const [events, setEvents] = useState({
    previousEvent: null,
    currentEvent: null,
    nextEvent: null
  });

  useEffect(() => {
    const fetchEvents = async () => {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const now = new Date();

      // Caching is disabled for now, but you can uncomment this part when ready.
      if (cachedData) {
        const { timestamp, data } = JSON.parse(cachedData);
        if (now - new Date(timestamp) < CACHE_EXPIRY) {
          setEvents(data);
          console.log('Using cached events data');
          return;
        }
      }

      try {
        const eventIds = await getEventIds();

        if (eventIds) {

          //Run all async api calls at once instead of one by one
          const [previousEvent, currentEvent, nextEvent] = await Promise.all([
            getEventDetails(eventIds.previousEventId),
            getEventDetails(eventIds.currentEventId),
            getEventDetails(eventIds.nextEventId)
          ]);

          const eventData = {
            previousEvent,
            currentEvent,
            nextEvent
          };

          console.log("Previous event: ", eventData.previousEvent);
          setEvents(eventData);
          localStorage.setItem(CACHE_KEY, JSON.stringify({ timestamp: now, data: eventData }));
          console.log('Event Data cached');

        }

      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchEvents();
  }, []);


  return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout/>}>
              <Route index element={<Landing events={events}/>} />
              <Route path="/events" element={<EventsPage events={events}/>}/>
              <Route path="/fighter/:name" element={<FighterPage/>}/>
              <Route path="/rankings" element={<RankingsPage/>}/>
              <Route path="/search" element={<SearchPage/>}/>
              <Route path="*" element={<NoPage/>}/>
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
  );
}

export default App;