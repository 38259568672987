import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Divider, Avatar, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EventCard = ({ event }) => {
    const [showAllFights, setShowAllFights] = useState(false);

    if (!event) return null;

    // Helper function to format the date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    // Toggle function for showing all fights
    const toggleShowAllFights = () => {
        setShowAllFights((prevShowAllFights) => !prevShowAllFights);
    };

    // Determine which fights to display, with the last ones first
    const fightsToDisplay = showAllFights
        ? [...event.competitions].reverse() // Reverse the entire array when showing all fights
        : event.competitions?.slice(-3).reverse(); // Reverse only the last 3 fights when not showing all

    return (
        <Card sx={{ minWidth: 275, mb: 2, bgcolor: '#333', color: '#fff', borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
                <Typography variant="h5" component="div" gutterBottom sx={{ color: '#fff', fontWeight: 'bold' }}>
                    {event.name || 'Unknown Event'}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom sx={{ color: '#aaa' }}>
                    {event.date ? formatDate(event.date) : 'Unknown Date'} - {event.venue?.fullName}, {event.venue?.address?.city}
                </Typography>
                <Divider sx={{ my: 4, bgcolor: '#444' }} />

                {fightsToDisplay?.map((fight, index) => (
                    <Box key={index} mb={2} sx={{ backgroundColor: '#444', borderRadius: 1, p: 2 }}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5} container direction="column" alignItems="center">
                                {fight.competitors[0].winner === true && (
                                    <Typography varient='h6' color='success'>
                                        Winner
                                    </Typography>
                                )}
                                <Avatar
                                    src={fight.competitors[0].athlete.flag.href || 'default-flag-url'}
                                    alt={fight.competitors[0].athlete.fullName}
                                    sx={{ width: 56, height: 56,  }}
                                />
                                <Typography variant="h6" component="div" sx={{ ml: 0, color: 'text.primary', textAlign: 'center' }}>
                                    {fight.competitors[0].athlete.fullName}
                                </Typography>

                                <Typography variant="body1" component="div" sx={{ ml: 0, color: 'text.secondary', textAlign: 'center' }}>
                                    {fight.competitors[0].records[0].summary}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="h6" align="center" sx={{ color: '#aaa' }}>
                                    vs
                                </Typography>
                            </Grid>
                            <Grid item xs={5} container direction="column" alignItems="center">
                                {fight.competitors[1].winner === true && (
                                    <Typography varient='h6' color='success'>
                                        Winner
                                    </Typography>
                                )}
                                <Avatar
                                    src={fight.competitors[1].athlete.flag.href || 'default-flag-url'}
                                    alt={fight.competitors[1].athlete.fullName}
                                    sx={{ width: 56, height: 56, }}
                                />
                                <Typography variant="h6" component="div" sx={{ mr: 0, color: 'text.primary', textAlign: 'center' }}>
                                    {fight.competitors[1].athlete.fullName}
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ ml: 0, color: 'text.secondary', textAlign: 'center' }}>
                                    {fight.competitors[1].records[0].summary}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1, color: '#ccc' }}>
                            {fight.type.abbreviation}
                        </Typography>

                        {fight.status.type.completed == true && (
                            <CheckCircleIcon color="success" sx={{ margin: 'auto', display: 'block' }} />
                        )}
                        {index < fightsToDisplay.length - 1 && (
                            <Divider sx={{ my: 2, bgcolor: '#444' }} />
                        )}
                    </Box>
                )) || <Typography>No fights available.</Typography>}

                {event.competitions?.length > 3 && (
                    <Button
                        onClick={toggleShowAllFights}
                        variant="text"
                        sx={{ mt: 2, color: '#fff', textTransform: 'none'}}
                    >
                        {showAllFights ? 'Show Less' : 'Show All Fights'}
                    </Button>
                )}
            </CardContent>
        </Card>
    );
};

export default EventCard;
