import { Box, Container, Grid, Typography } from "@mui/material";
import EventCard from "../Components/EventCard";
import React, {useEffect} from "react";

const EventsPage = ({ events }) => {

    useEffect(() => {
        if(events){
            console.log("Event: ", events.previousEvent);
        }
    });

    return (
        <Box sx={{ bgcolor: 'primary.main', minHeight: '100vh' }}>
            <Container maxWidth="lg">
                <Typography variant="h4" align="center" color="text.primary" sx={{ paddingBottom: 20 , paddingTop: 10}}>
                    Upcoming UFC Events
                </Typography>
                <Grid container spacing={3}  justifyContent={'center'} >
                    {events.previousEvent && (
                        <Grid item xs={12} sm={8} md={4}>
                            <Typography variant="h6" gutterBottom align="center" color="text.primary">
                                Last Event
                            </Typography>
                            <EventCard event={events.previousEvent} showAllFights={false} />
                        </Grid>
                    )}
                    {events.currentEvent && (
                        <Grid item xs={12} sm={8} md={4}>
                            <Typography variant="h6" gutterBottom align="center" color="text.primary">
                                Current Event
                            </Typography>
                            <EventCard event={events.currentEvent} showAllFights={true} />
                        </Grid>
                    )}
                    {events.nextEvent && (
                        <Grid item xs={12} sm={8} md={4}>
                            <Typography variant="h6" gutterBottom align="center" color="text.primary">
                                Next Event
                            </Typography>
                            <EventCard event={events.nextEvent} showAllFights={false} />
                        </Grid>
                    )}
                </Grid>
                {!events.previousEvent && !events.currentEvent && !events.nextEvent && (
                    <Typography variant="h6" align="center" sx={{ mt: 4 }} color="text.primary">
                        No events available at this time.
                    </Typography>
                )}
            </Container>
        </Box>
    );
};

export default EventsPage;
