import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { getDivisionalRanking } from "../API";
import RankingCard from "../Components/RankingCard";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Box,
    Link,
    Container, Button, Grid, CircularProgress
} from '@mui/material';

const CACHE_KEY = 'ufcRankingsCache';
const CACHE_EXPIRY = 24 * 60 * 60 * 1000; // 24 hours

function RankingsPage() {
    const [rankings, setRankings] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            const data = await getDivisionalRanking();
            if (data) {
                setRankings(data.data);
                localStorage.setItem(CACHE_KEY, JSON.stringify({ timestamp: now, data: data.data }));
                console.log('Rank Data cached');
                setLoading(false);
            } else {
                console.log('Failed to fetch rankings');
            }
        };

        const cachedData = localStorage.getItem(CACHE_KEY);
        const now = new Date();

        // Check for cached data, if there are load them and continue.
        if (cachedData) {
            const {timestamp, data} = JSON.parse(cachedData);
            if (now - new Date(timestamp) < CACHE_EXPIRY) {
                setRankings(data);
                setLoading(false);
                console.log('Using cached rank data');
                return;
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        console.log("Rankings: ", rankings);
    })

    if(loading) {
        return (
            <Container
                maxWidth={false}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    textAlign: 'center',
                    bgcolor: 'primary.main',
                    color: 'text.primary'
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Loading Divisional Rankings
                </Typography>
                <CircularProgress/>

            </Container>
        )
    }

    if (rankings) {
        return (
            <Container
                maxWidth="lg"
                sx={{
                    paddingY: 5,
                    bgcolor: 'primary.main',
                    color: 'text.primary'
                }}
            >
                <Typography variant="h4" align="center" gutterBottom>
                    Divisional Rankings
                </Typography>

                <Grid container spacing={3} justifyContent={'center'}>
                    {rankings.map((rank, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <RankingCard rank={rank} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        );
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                textAlign: 'center',
                bgcolor: 'primary.main',
                color: 'text.primary'
            }}
        >
            <Typography variant="h1" gutterBottom sx={{ }}>
                ERROR 404
            </Typography>
            <Typography variant="h5" gutterBottom>
                Failed to load Divisional Rankings...
            </Typography>

        </Container>
    );


}

export default RankingsPage